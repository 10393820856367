import type {MainNavbarState} from "./mainNavbarReducer";

export type NavbarState = {
    mainNavbar: MainNavbarState;
};

export const getSubmenuOpenState = (state: NavbarState) => state.mainNavbar.submenuOpen;
export const isShopMatched = (state: NavbarState) => state.mainNavbar?.shopMatch;
export const isLoading = (state: NavbarState) => state.mainNavbar.loading;
export const animationState = (state: NavbarState) => state.mainNavbar.animationState;
export const getScrollPosition = (state: NavbarState) => state.mainNavbar.scrollPosition;
